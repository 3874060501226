import AcceptAgreementCheckbox from "@components/Checkbox/AcceptAgreementCheckbox";
import { Spinner } from "@components/Spinner";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import useAnonymousWebshop from "@hooks/useAnonymousWebshop";
import { AiFillInfoCircle } from "react-icons/ai";
import { isEmpty } from "lodash";
import AnonymousRadioCards from "@components/AnonymousWebshop/AnonymousRadioCards";
import MediaQuery from "react-responsive";

const AnonymousWebshop = () => {
  const intl = useIntl();
  const [selected, setSelected] = useState();
  const {
    handleSubmit,
    register,
    errors,
    trigger,
    watch,
    saleItemsPrepaidWash,
    addPrepaidSaleItem,
    render,
  } = useAnonymousWebshop();

  const purchaserEmail = watch("purchaserEmail");
  const verifyEmail = watch("verifyEmail");

  useEffect(() => {
    if (selected && !isEmpty(purchaserEmail) && !isEmpty(verifyEmail)) {
      trigger("purchaserEmail");
      trigger("verifyEmail");
    }
  }, [purchaserEmail, verifyEmail, selected]);

  if (!render) return <Spinner />;

  return (
    <div className={"webshop"}>
      <Form onSubmit={handleSubmit(addPrepaidSaleItem)}>
        <Row className={"pt-3"}>
          <Col lg="12">
            <Card className={"products"}>
              <CardBody>
                <CardTitle className={"pb-3 mb-0"}>
                  <FormattedMessage id={"main.webshop.title"} />
                </CardTitle>
                {saleItemsPrepaidWash.length ? (
                  <AnonymousRadioCards
                    items={saleItemsPrepaidWash}
                    setSelected={setSelected}
                    selected={selected}
                    errors={errors}
                    register={register}
                    watch={watch}
                    trigger={trigger}
                  />
                ) : (
                  <CardText>
                    <FormattedMessage id={"main.anonymousWebshop.noItems"} />
                  </CardText>
                )}
              </CardBody>
            </Card>
            {selected && (
              <MediaQuery query="(min-width: 1025px)">
                <Card className={"products"}>
                  <CardBody>
                    <Row>
                      <Col md={"6"}>
                        <FormGroup className={"mb-3"}>
                          <label
                            className="control-label label-required"
                            htmlFor="purchaserEmail"
                          >
                            <FormattedMessage id="anonymous.webshop.email" />
                          </label>
                          <Input
                            type="text"
                            name="purchaserEmail"
                            id="purchaserEmail"
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                            }}
                            innerRef={register({
                              required: intl.formatMessage({
                                id: "validation.required",
                              }),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: intl.formatMessage({
                                  id: "validation.email",
                                }),
                              },
                              validate: {
                                emailEqual: (value) =>
                                  value === watch("verifyEmail") || (
                                    <FormattedMessage
                                      id={"validation.email.notEqual"}
                                    />
                                  ),
                              },
                            })}
                          />
                          {errors.purchaserEmail &&
                            errors.purchaserEmail.type !== "emailEqual" && (
                              <p className="text-danger mt-2">
                                {errors.purchaserEmail.message}
                              </p>
                            )}
                        </FormGroup>
                      </Col>
                      <Col md={"6"}>
                        <FormGroup className={"w-100 mb-3"}>
                          <label
                            className="control-label label-required"
                            htmlFor="verifyEmail"
                          >
                            <FormattedMessage id="anonymous.webshop.verify_email" />
                          </label>
                          <Input
                            type="text"
                            name="verifyEmail"
                            id="verifyEmail"
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                            }}
                            innerRef={register({
                              required: intl.formatMessage({
                                id: "validation.required",
                              }),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: intl.formatMessage({
                                  id: "validation.email",
                                }),
                              },
                              validate: {
                                emailEqual: (value) =>
                                  value === watch("purchaserEmail") || (
                                    <FormattedMessage
                                      id={"validation.email.notEqual"}
                                    />
                                  ),
                              },
                            })}
                          />
                          {errors.verifyEmail &&
                            errors.verifyEmail.type !== "emailEqual" && (
                              <p className="text-danger mt-2">
                                {errors.verifyEmail.message}
                              </p>
                            )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        {errors.verifyEmail &&
                          errors.verifyEmail.type === "emailEqual" && (
                            <div className={"mb-3"}>
                              <span className="text-danger">
                                {errors.verifyEmail.message}
                              </span>
                            </div>
                          )}
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <div className={"d-flex align-items-center"}>
                          <AiFillInfoCircle />
                          <span className={"ml-2"}>
                            <FormattedMessage
                              id={"anonymous.webshop.email_reason"}
                            />
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className={"pay mb-3"}>
                  <CardBody>
                    <AcceptAgreementCheckbox
                      agreementMessage={"main.acceptAgreement"}
                      agreementUrlText={"main.saleAgreementUrl"}
                      errors={errors}
                      register={register}
                      identifier={"agreement"}
                    />
                    <Button color={"primary"}>
                      <FormattedMessage id={"main.webshop.cta"} />
                    </Button>
                  </CardBody>
                </Card>
              </MediaQuery>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AnonymousWebshop;
