import { myPrepaidSaleItems } from "@store/reducers/prepaidSaleItems";
import { getSaleItems } from "@store/reducers/saleItemCategories";
import {
  getSuccessfulSaleByUuidAction,
  setSaleItemData,
} from "@store/reducers/sales";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useAnonymousCheckout from "@hooks/useAnonymousCheckout";

const useAnonymousWebshop = () => {
  const CATEGORY_CODE_PREPAID = "PREPAID";

  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, watch, trigger } = useForm();
  const checkout = useAnonymousCheckout();

  const saleItemsPrepaidWash = useSelector((state) =>
    state.saleItem.list[CATEGORY_CODE_PREPAID]?.map((obj) => {
      return { ...obj, name: obj.name };
    }).sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
  );

  const list = useSelector((state) =>
    state.prepaidSaleItem.list
      ?.map((obj) => {
        return { ...obj, dateAdd: new Date(obj.dateAdd) };
      })
      .sort((a, b) => b.dateAdd - a.dateAdd)
  );

  const listPrepaid = useSelector((state) => state.saleItem.saleItemById);

  const [render, setRender] = useState(false);

  const fetchSaleItems = async () => {
    try {
      await dispatch(getSaleItems({ categoryCode: CATEGORY_CODE_PREPAID }));
    } catch (e) {
      console.error(e);
    }
  };

  const getSuccessfulSaleByUuid = async (uuid) => {
    try {
      await dispatch(getSuccessfulSaleByUuidAction(uuid));
    } catch (e) {
      console.error(e);
    }
  };

  const addPrepaidSaleItem = async (data) => {
    await dispatch(
      setSaleItemData({
        id: Number(data.saleItemId),
        purchaserEmail: data.purchaserEmail,
      })
    );

    checkout.handleCheckout();
  };

  useEffect(async () => {
    await fetchSaleItems();

    setRender(true);
  }, []);

  return {
    register,
    handleSubmit,
    errors,
    reset,
    trigger,
    watch,
    getSuccessfulSaleByUuid,
    saleItemsPrepaidWash: saleItemsPrepaidWash || [],
    addPrepaidSaleItem,
    myPrepaidSaleItems,
    list: list || [],
    listPrepaid,
    render,
  };
};
export default useAnonymousWebshop;
