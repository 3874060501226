import { myAccount } from "@store/reducers/profile";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getIdentificationItemTypes } from "@store/reducers/identificationItemTypes";

const useRenderCompleteAccount = () => {
  const [render, setRenderCompleteAccount] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCustomerData = async () => {
      try {
        await dispatch(myAccount());
        dispatch(getIdentificationItemTypes());
        setRenderCompleteAccount(true);
      } catch (e) {
        console.error(e);
      }
    };

    getCustomerData();
  }, []);

  const setRender = (isRender) => setRenderCompleteAccount(isRender);

  return {
    setRender,
    render,
  };
};

export default useRenderCompleteAccount;
