import { Tooltip } from "@components/Tooltip";
import React, { useEffect } from "react";
import { AiFillInfoCircle, AiOutlineInfoCircle } from "react-icons/ai";
import { FormattedMessage, useIntl } from "react-intl";
import MediaQuery from "react-responsive";
import {
  Button,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Progress,
} from "reactstrap";
import { isEmpty } from "lodash";

const AnonymousRadioCards = ({
  items,
  setSelected,
  selected,
  register,
  errors,
  watch,
  trigger,
}) => {
  const intl = useIntl();

  const purchaserEmail = watch("purchaserEmail");
  const verifyEmail = watch("verifyEmail");

  useEffect(() => {
    if (selected && !isEmpty(purchaserEmail) && !isEmpty(verifyEmail)) {
      trigger("purchaserEmail");
      trigger("verifyEmail");
    }
  }, [purchaserEmail, verifyEmail, selected]);

  return (
    <FormGroup className={"radio-card-group"}>
      {items.map(
        (
          {
            id,
            name,
            description,
            price,
            availableQuantity,
            offeredQuantity,
            prepaidSaleItemDefinition,
          },
          index
        ) => {
          return (
            <FormGroup
              className={`radio-card ${errors.saleItemId && "error"}`}
              key={index}
            >
              <Label
                onClick={() =>
                  availableQuantity !== 0 && setSelected(`radio-card-${index}`)
                }
              >
                {availableQuantity !== 0 ? (
                  <Input
                    type={"radio"}
                    name={"saleItemId"}
                    value={id}
                    innerRef={register({
                      required: intl.formatMessage({
                        id: "validation.required",
                      }),
                    })}
                  />
                ) : null}
                <div className={"title-group"}>
                  <h3>
                    {name}{" "}
                    <Tooltip
                      content={description}
                      placement={"top"}
                      tooltipId={`tooltip-id-${index}`}
                    >
                      <AiOutlineInfoCircle id={`tooltip-id-${index}`} />
                    </Tooltip>
                  </h3>
                  {prepaidSaleItemDefinition ? (
                    <>
                      <h4>
                        {price !==
                        prepaidSaleItemDefinition.saleItemPrice *
                          prepaidSaleItemDefinition.quantity ? (
                          <>
                            <s>€ {prepaidSaleItemDefinition.saleItemPrice}</s>{" "}
                          </>
                        ) : null}
                        €{" "}
                        {Number.isInteger(
                          price / prepaidSaleItemDefinition.quantity
                        )
                          ? price / prepaidSaleItemDefinition.quantity
                          : (
                              price / prepaidSaleItemDefinition.quantity
                            ).toFixed(2)}
                      </h4>

                      <div className={"bulk"}>
                        <p className={"bulk-price"}>
                          {prepaidSaleItemDefinition.quantity === 1 ? (
                            <FormattedMessage
                              id={`main.webshop.oneFor`}
                              values={{
                                quantity: prepaidSaleItemDefinition.quantity,
                                price: price,
                              }}
                            />
                          ) : (
                            <FormattedMessage
                              id={`main.webshop.multipleItemsFor`}
                              values={{
                                quantity: prepaidSaleItemDefinition.quantity,
                                price: price,
                              }}
                            />
                          )}
                        </p>
                        {price !==
                        prepaidSaleItemDefinition.saleItemPrice *
                          prepaidSaleItemDefinition.quantity ? (
                          <p className={"bulk-advantage"}>
                            <FormattedMessage
                              id={`main.webshop.advantagePrice`}
                              values={{
                                price:
                                  prepaidSaleItemDefinition.saleItemPrice *
                                    prepaidSaleItemDefinition.quantity -
                                  price,
                              }}
                            />
                          </p>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <h4>€ {price}</h4>
                  )}
                </div>

                {offeredQuantity && availableQuantity ? (
                  <div className={"progress-group"}>
                    <Progress
                      value={offeredQuantity - availableQuantity}
                      max={offeredQuantity}
                      color={"progress-bar"}
                    />
                    <p className={"progress-help-text"}>
                      {`${availableQuantity}/${offeredQuantity} `}{" "}
                      <FormattedMessage id={`main.webshop.items.available`} />
                    </p>
                  </div>
                ) : availableQuantity === 0 ? (
                  <div className={"progress-group"}>
                    <p className={"text-danger"} style={{ lineHeight: 1 }}>
                      <FormattedMessage id={`main.webshop.soldOut`} />
                    </p>
                  </div>
                ) : null}
                <MediaQuery query="(max-width: 1024px)">
                  {selected === `radio-card-${index}` && (
                    <MediaQuery query="(max-width: 1024px)">
                      <>
                        <FormGroup className={"mt-4 mb-3"}>
                          <label
                            className="control-label label-required"
                            htmlFor="purchaserEmail"
                          >
                            <FormattedMessage id="anonymous.webshop.email" />
                          </label>
                          <Input
                            type="text"
                            name="purchaserEmail"
                            id="purchaserEmail"
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                            }}
                            innerRef={register({
                              required: intl.formatMessage({
                                id: "validation.required",
                              }),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: intl.formatMessage({
                                  id: "validation.email",
                                }),
                              },
                              validate: {
                                emailEqual: (value) =>
                                  value === watch("verifyEmail") || (
                                    <FormattedMessage
                                      id={"validation.email.notEqual"}
                                    />
                                  ),
                              },
                            })}
                          />
                          {errors.purchaserEmail &&
                            errors.purchaserEmail.type !== "emailEqual" && (
                              <p className="text-danger mt-2">
                                {errors.purchaserEmail.message}
                              </p>
                            )}
                        </FormGroup>
                        <FormGroup className={"w-100 mb-3"}>
                          <label
                            className="control-label label-required"
                            htmlFor="verifyEmail"
                          >
                            <FormattedMessage id="anonymous.webshop.verify_email" />
                          </label>
                          <Input
                            type="text"
                            name="verifyEmail"
                            id="verifyEmail"
                            onBlur={(e) => {
                              e.target.value = e.target.value.trim();
                            }}
                            innerRef={register({
                              required: intl.formatMessage({
                                id: "validation.required",
                              }),
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: intl.formatMessage({
                                  id: "validation.email",
                                }),
                              },
                              validate: {
                                emailEqual: (value) =>
                                  value === watch("purchaserEmail") || (
                                    <FormattedMessage
                                      id={"validation.email.notEqual"}
                                    />
                                  ),
                              },
                            })}
                          />
                          {errors.verifyEmail &&
                            errors.verifyEmail.type !== "emailEqual" && (
                              <p className="text-danger mt-2">
                                {errors.verifyEmail.message}
                              </p>
                            )}
                        </FormGroup>
                        {errors.verifyEmail &&
                          errors.verifyEmail.type === "emailEqual" && (
                            <div className={"mb-3"}>
                              <span className="text-danger">
                                {errors.verifyEmail.message}
                              </span>
                            </div>
                          )}
                        <div className={"d-flex align-items-center"}>
                          <AiFillInfoCircle />
                          <span className={"ml-2 w-100"}>
                            <FormattedMessage
                              id={"anonymous.webshop.email_reason"}
                            />
                          </span>
                        </div>
                        <CustomInput
                          type="checkbox"
                          name="agreement"
                          id="agreement"
                          label={
                            <FormattedMessage
                              id={"main.webshop.agreementLabel"}
                            />
                          }
                          className={"agreement"}
                        />
                        <Button>
                          <FormattedMessage id={"main.webshop.cta"} />
                        </Button>
                      </>
                    </MediaQuery>
                  )}
                </MediaQuery>
              </Label>
            </FormGroup>
          );
        }
      )}
    </FormGroup>
  );
};

export default AnonymousRadioCards;
